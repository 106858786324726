<template>
    <div>
        
        <a-modal
        
        v-model="modals"
        @ok="handleOk"
        :footer="null"
        width:="640px"
        :centered="true"
        
        >
          <div slot="title">
           Informasi Status
          </div>
          
          <div class="plan-content">

            <div class="infostatus">
                <div>
                    <h3 style="color:#00c4c4">Menunggu Verifikasi</h3>
                    <p>Data masih dalam antrian proses.</p>
                </div>
                 <div>
                    <h3 style="color:#4C88FF">Sedang Dalam Proses</h3>
                    <p>Data sedang dalam proses verifikasi oleh tim Data Management kami. Mohon ditunggu ya.</p>
                </div>
                <div>
                    <h3 style="color:#333">Hold</h3>
                    <p>Ada data yang perlu dikonfirmasi lebih lanjut, silahkan cek email untuk detailnya ya.</p>
                </div>
                
                <div>
                    <h3 style="color:#20CC86">Approved</h3>
                    <p>Data sudah diverifikasi oleh tim Data Management kami, sedang diajukan ke Asuransi.</p>
                </div>
                <div>
                    <h3 style="color:#d294cc">Dibatalkan</h3>
                    <p>Data tidak diproses lebih lanjut, silahkan cek email untuk detailnya.</p>
                </div>

                
            </div>
          </div>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    beforeCreate(){
        this.form = this.$form.createForm(this,{name:'FormAction'})
    },
    data() {
        return {
            modals: false,
        
            isLoading: false,
          
          
        }
    },
      mounted() {
          document.body.classList.add("ModalCustom");
        
        
        
  },
  destroyed() {
    document.body.classList.remove("ModalCustom");
  },
    methods: {
        moment,
        handleOk() {
            this.modals = false
        },
        handleCancel() {
            this.modals = true            
        },
       
        Show(item) {
          
            this.modals = true
         

        
            
        },
        
    }
}
</script>

<style>
.ModalCustom .ant-modal-content{
    padding:2px 5px !important;
   
   
}

.ModalCustom  .ant-modal-header{
 border-radius: 24px !important;

 }

 .ModalCustom .ant-modal-close-x{
    margin-right: 20px;
 }

</style>
