var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"footer":null,"width:":"640px","centered":true},on:{"ok":_vm.handleOk},model:{value:(_vm.modals),callback:function ($$v) {_vm.modals=$$v},expression:"modals"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"titlemodalaction"},[_c('img',{attrs:{"src":require(`@/assets/img/icon/${_vm.iconStatus == 'DELETE' ? 'delete.svg' : (_vm.iconStatus == 'REAKTIF' ? 'reaktif.svg' : ( (_vm.iconStatus == 'MUTASI' ? 'mutasi.svg' : 'reprint.svg') ) )}`)}}),_c('h4',[_vm._v(" "+_vm._s(_vm.iconStatus == 'DELETE' ? 'Hapus': (_vm.iconStatus == 'REAKTIF' ? 'Reaktif' : (_vm.iconStatus == 'MUTASI' ? 'Mutasi' : 'Reprint') ))+" Data Peserta")])])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.Submit}},[_c('a-form-item',{attrs:{"label":"Tgl Efektif"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tgl_efektif',{
                      rules:[{
                        required:true,
                        message:'Field ini harus diisi'
                      }]  
                    }]),expression:"['tgl_efektif',{\n                      rules:[{\n                        required:true,\n                        message:'Field ini harus diisi'\n                      }]  \n                    }]"}],staticStyle:{"width":"100%"},attrs:{"disabled-date":_vm.iconStatus == 'DELETE' ? _vm.disabledDate : false,"autocomplete":"off","format":"DD MMM YYYY"}})],1),(_vm.iconStatus == 'MUTASI')?_c('a-form-item',{attrs:{"label":"Nama Perusahaan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'nama_perusahaan',
                  {
                    rules: [
                      { required: true, message: 'Field ini harus diisi' },
                    ],
                  },
                ]),expression:"[\n                  'nama_perusahaan',\n                  {\n                    rules: [\n                      { required: true, message: 'Field ini harus diisi' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Nama Perusahaan"}},_vm._l((_vm.perusahaan),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id,"disabled":item.id == _vm.idperusahaan ? true:false}},[_vm._v(_vm._s(item.nama_perusahaan))])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"Nama Peserta"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id_peserta',{
                initialValue:_vm.id
            }]),expression:"['id_peserta',{\n                initialValue:id\n            }]"}],attrs:{"mode":"multiple","disabled":true}},[_c('a-select-option',{attrs:{"value":_vm.id}},[_vm._v(" "+_vm._s(_vm.peserta)+" ")])],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"right","margin-bottom":"15px"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.isLoading}},[_vm._v("Kirim")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }