<template>
    <div>
        
        <a-modal
        v-model="modals"
        @ok="handleOk"
        :footer="null"
        width:="640px"
        :centered="true"
        
        >
            <div slot="title">
                <div class="titlemodalaction">
                    <img :src="require(`@/assets/img/icon/${iconStatus == 'DELETE' ? 'delete.svg' : (iconStatus == 'REAKTIF' ? 'reaktif.svg' : ( (iconStatus == 'MUTASI' ? 'mutasi.svg' : 'reprint.svg') ) )}`)" />
                <h4> {{ iconStatus == 'DELETE' ? 'Hapus': (iconStatus == 'REAKTIF' ? 'Reaktif' : (iconStatus == 'MUTASI' ? 'Mutasi' : 'Reprint') )  }} Data Peserta</h4>
                </div>
            </div>
         
          <a-form :form="form" @submit="Submit">

            <a-form-item label="Tgl Efektif">
                 <a-date-picker
                 :disabled-date="iconStatus == 'DELETE' ? disabledDate : false"
                        autocomplete="off"
                        v-decorator="['tgl_efektif',{
                          rules:[{
                            required:true,
                            message:'Field ini harus diisi'
                          }]  
                        }]"
                        style="width: 100%"
                        format="DD MMM YYYY"
                      />
            </a-form-item>
            <a-form-item label="Nama Perusahaan" v-if="iconStatus == 'MUTASI'" >
                <a-select v-decorator="[
                      'nama_perusahaan',
                      {
                        rules: [
                          { required: true, message: 'Field ini harus diisi' },
                        ],
                      },
                    ]"
                placeholder="Nama Perusahaan">
                <a-select-option
                      v-for="(item, i) in perusahaan"
                      :key="i"
                      :value="item.id"
                      :disabled="item.id == idperusahaan ? true:false"
                      >{{ item.nama_perusahaan }}</a-select-option
                    >
                 
            </a-select>
            
            </a-form-item>
            <a-form-item label="Nama Peserta">
                <a-select mode="multiple"
                v-decorator="['id_peserta',{
                    initialValue:id
                }]"
                :disabled="true">
                <a-select-option  :value="id">
                    {{ peserta }}
                </a-select-option>
                </a-select>
            </a-form-item>
            <div style="display: flex; justify-content: right; margin-bottom: 15px;">
                <a-button type="primary" html-type="submit" :loading="isLoading">Kirim</a-button>
            </div>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    beforeCreate(){
        this.form = this.$form.createForm(this,{name:'FormAction'})
    },
    data() {
        return {
            modals: false,
            fileList: [],
            iconStatus: null,
            id: '',
            peserta: '',
            isLoading: false,
            perusahaan: [],
            idJobOrder: '',
            idperusahaan: '',
              datedisable:0
        }
    },
      mounted() {
          document.body.classList.add("ModalCustom");
        
          
        
  },
  destroyed() {
    document.body.classList.remove("ModalCustom");
  },
    methods: {
        moment,
        beforeUpload(){},
        handleRemove(){},
        handleOk() {
            this.modals = false
        },
        handleCancel() {
            this.modals = true            
        },
        getPerusahaan() {
        this.$store
            .dispatch("GetDaftarPerusahaanMutasi", {
            page: 1,
            pageSizes: 15,
            id: this.idJobOrder,
            })
            .then((response) => {
            this.perusahaan = response.data.data;
            
            });
        },

        Show(item,id,namapeserta,joborder,idperusahaan,tgl_efektif,status) {
            
            this.iconStatus = item
            this.idJobOrder = joborder
            this.idperusahaan = idperusahaan
            //console.log(tgl_efektif)
             this.datedisabled(item,joborder)
           
             this.form.getFieldDecorator("nama_perusahaan", {
            initialValue: idperusahaan,
            preserve: true,
            });
            this.form.getFieldDecorator("tgl_efektif", {
            initialValue: tgl_efektif,
            preserve: true,
            });
           

            if (item == 'MUTASI') {
                if(status =='Employee'){
                    this.getPerusahaan();    
                    this.modals = true
                } else {
                    this.$error({
                        title: 'Alert',
                        content:'Mutasi hanya untuk status Employee'
                    })
                }
                

               
            } else {
                this.modals = true
            }

          
          
            this.peserta = namapeserta
            this.id = id
         
            this.form.resetFields()
            
        },
         disabledDate(current) {
            //console.log(current)
             return current && current <= moment().subtract((parseInt(this.datedisable) + 1),"days");
        },
        datedisabled(item,joborder) {
            if(item =='DELETE'){
                this.$store.dispatch('getDateDisabled', {
                    movement: item.toUpperCase(),
                    job_order:joborder
                }).then(response => {
                    this.datedisable = response.data.data.ketentuan
                    //console.log(moment().subtract(this.datedisable,"days"))
                })
            } else {
                this.datedisable = 0
            }
       },
        Submit(e) {
            e.preventDefault()
           
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.isLoading = true

                  
                    if (this.iconStatus == 'MUTASI') {

                          console.log('icon',values)
                        this.$store.dispatch('ActionDataPesertaQue', {
                            id_peserta: values.id_peserta,
                            tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
                            link: this.iconStatus.toLowerCase(),
                            id: this.id,
                            nama_perusahaan: values.nama_perusahaan,
                            joborder: this.idJobOrder,
                        }).then(response => {
                            this.$notification.success({
                                message: response.data.message
                            })
                            this.isLoading = false
                              this.$emit('fetch') 
                            this.modals = false
                        }).catch((error) => {
                              this.isLoading = false
                        })
                    } else {
                        this.$store.dispatch('ActionDataPesertaQue', {
                        id_peserta: values.id_peserta,
                        tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
                        link: this.iconStatus.toLowerCase(),
                            id: this.id,
                        joborder:this.idJobOrder,
                    }).then(response => {
                        this.$notification.success({
                            message:response.data.message
                        })
                        this.isLoading = false
                         this.$emit('fetch') 
                        this.modals = false
                    }).catch((error) => {
                              this.isLoading = false
                        })
                    }
                    
                }
            })
        }
    }
}
</script>

<style>
.ModalCustom .ant-modal-content{
    padding:2px 5px !important;
   
   
}

.ModalCustom  .ant-modal-header{
 border-radius: 24px !important;

 }

 .ModalCustom .ant-modal-close-x{
    margin-right: 20px;
 }

</style>
